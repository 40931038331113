import emobiAc22 from "../../assets/img/pins/emobi_ac_gyors.svg";
import emobiAc22Christmas from "../../assets/img/pins/emobi_ac_gyors_karacsony.svg";
import emobiDc22 from "../../assets/img/pins/emobi_dc_gyors.svg";
import emobiDc22Christmas from "../../assets/img/pins/emobi_dc_gyors_karacsony.svg";
import emobiDc50 from "../../assets/img/pins/emobi_dc_villam.svg";
import emobiDc50Christmas from "../../assets/img/pins/emobi_dc_villam_karacsony.svg";
import ionity from "../../assets/img/pins/ionity.svg";
import mobilitiDc22 from "../../assets/img/pins/mobiliti_dc22.svg";
import mobilitiDc50 from "../../assets/img/pins/mobiliti_dc50.svg";
import mobilitiDc50Christmas from "../../assets/img/pins/mobiliti_dc50_karacsony.svg";
import mobilitiDc70 from "../../assets/img/pins/mobiliti_dc70.svg";
import mobilitiRapid70 from "../../assets/img/pins/mobiliti_rapid_70.svg";
import mobilitiSima from "../../assets/img/pins/mobiliti_sima.svg";
import mobilitiSimaChristmas from "../../assets/img/pins/mobiliti_sima_karacsony.svg";
import greenYellowPin from "../../assets/img/pins/normal_ac_max22kw.svg";
import greenYellowPinChristmas from "../../assets/img/pins/normal_ac_max22kw_karacsony.svg";
import privatePin from "../../assets/img/pins/private_2.svg";
import privatePinChristmas from "../../assets/img/pins/private_karacsony.svg";
import roamingAcPin from "../../assets/img/pins/roaming_ac.svg";
import roamingAcPinChristmas from "../../assets/img/pins/roaming_ac_karacsony.svg";
import roamingDcPin from "../../assets/img/pins/roaming_dc.svg";
import roamingDcPinChristmas from "../../assets/img/pins/roaming_dc_karacsony.svg";
import shellPin from "../../assets/img/pins/shell.svg";
import shellPinChristmas from "../../assets/img/pins/shell_karacsony.svg";
import sparPin from "../../assets/img/pins/spar.svg";
import sparPinChristmas from "../../assets/img/pins/spar_karacsony.svg";
import teslaSlowPin from "../../assets/img/pins/tesla_ac.svg";
import teslaSlowPinChristmas from "../../assets/img/pins/tesla_ac_karacsony.svg";
import teslaSuperchargerPin from "../../assets/img/pins/tesla_dc.svg";
import teslaSuperchargerPinChristmas from "../../assets/img/pins/tesla_dc_karacsony.svg";
import yellowGreenPin from "../../assets/img/pins/villam_ac_dc.svg";
import yellowGreenPinChristmas from "../../assets/img/pins/villam_ac_dc_karacsony.svg";

export const CustomMarkers = {
  EMOBI_AC: "EMOBI_AC",
  EMOBI_DC22: "EMOBI_DC22",
  EMOBI_DC50: "EMOBI_DC50",
  SPAR: "SPAR",
  SHELL: "SHELL",
  ROAMING_AC: "ROAMING_AC",
  ROAMING_DC: "ROAMING_DC",
  MOBILITI_DC22: "MOBILITI_DC22",
  IONITY: "IONITY",
};

const isIonityCharger = (location) => {
  if (location?.evses && location?.evses.length && location?.marker === "IONITY") {
    return location.evses.some((charger) => charger.power >= 300000);
  }
  return false;
};

export const getIcon = (location, isSnowing = false) => {
  if (location?.roaming && location?.currentType === "AC") {
    return isSnowing ? roamingAcPinChristmas : roamingAcPin;
  }
  if (isIonityCharger(location)) {
    return isSnowing ? ionity : ionity;
  }
  if (location?.roaming && location?.currentType === "DC") {
    return isSnowing ? roamingDcPinChristmas : roamingDcPin;
  }
  if (location?.usageType.toLowerCase() === "private") {
    return isSnowing ? privatePinChristmas : privatePin;
  }
  if (location?.marker.toLowerCase() === "mobiliti_rapid_70") {
    return mobilitiRapid70;
  }
  if (location?.manufacturer === "Tesla") {
    if (location?.evses.some((charger) => charger.amperage === 350)) {
      return isSnowing ? teslaSuperchargerPinChristmas : teslaSuperchargerPin;
    }
    return isSnowing ? teslaSlowPinChristmas : teslaSlowPin;
  } else if (location?.customMarker === CustomMarkers.EMOBI_DC50) {
    return isSnowing ? emobiDc50Christmas : emobiDc50;
  } else if (location?.customMarker === CustomMarkers.EMOBI_DC22) {
    return isSnowing ? emobiDc22Christmas : emobiDc22;
  } else if (location?.customMarker === CustomMarkers.EMOBI_AC) {
    return isSnowing ? emobiAc22Christmas : emobiAc22;
  } else if (location?.customMarker === CustomMarkers.SPAR) {
    return isSnowing ? sparPinChristmas : sparPin;
  } else if (location?.customMarker === CustomMarkers.SHELL) {
    return isSnowing ? shellPinChristmas : shellPin;
  } else if (location?.customMarker === CustomMarkers.MOBILITI_DC22) {
    return isSnowing ? mobilitiDc22 : mobilitiDc22;
  } else if (location?.customMarker === CustomMarkers.ROAMING_AC) {
    return isSnowing ? roamingAcPinChristmas : roamingAcPin;
  } else if (location?.customMarker === CustomMarkers.ROAMING_DC) {
    return isSnowing ? roamingDcPinChristmas : roamingDcPin;
  } else if (location?.ocpi) {
    if (location?.evses.some((charger) => charger.power >= 70000)) {
      return isSnowing ? mobilitiDc70 : mobilitiDc70;
    }
    if (location?.evses.some((charger) => charger.power >= 40000)) {
      return isSnowing ? mobilitiDc50Christmas : mobilitiDc50;
    }
    // if (location?.evses.find((charger) => charger.power >= 22000)) {
    //   return isSnowing ? mobilitiDc22 : mobilitiDc22;
    // }
    return isSnowing ? mobilitiSimaChristmas : mobilitiSima;
  } else if (location?.evses.some((charger) => charger.power <= 22000)) {
    return isSnowing ? greenYellowPinChristmas : greenYellowPin;
  }
  return isSnowing ? yellowGreenPinChristmas : yellowGreenPin;
};

export const getZIndexForPin = (location) => {
  if (location?.roaming && location?.currentType === "AC") {
    return 4;
  }
  if (location?.roaming && location?.currentType === "DC") {
    return 5;
  }
  if (location?.usageType.toLowerCase() === "private") {
    return 6;
  }
  if (location?.manufacturer === "Tesla") {
    return 1;
  } else if (location?.customMarker === CustomMarkers.EMOBI_DC50) {
    return 11;
  } else if (location?.customMarker === CustomMarkers.EMOBI_DC22) {
    return 9;
  } else if (location?.customMarker === CustomMarkers.EMOBI_AC) {
    return 7;
  } else if (location?.customMarker === CustomMarkers.SPAR) {
    return 6;
  } else if (location?.customMarker === CustomMarkers.SHELL) {
    return 10;
  } else if (location?.customMarker === CustomMarkers.ROAMING_AC) {
    return 4;
  } else if (location?.customMarker === CustomMarkers.ROAMING_DC) {
    return 5;
  } else if (location?.customMarker === CustomMarkers.MOBILITI_DC22) {
    return 12;
  } else if (location?.ocpi) {
    if (location?.evses.some((charger) => charger.power >= 70000)) {
      return 13;
    }
    if (location?.evses.some((charger) => charger.power >= 40000)) {
      return 12;
    }
    return 8;
  } else if (location?.evses.some((charger) => charger.power <= 22000)) {
    return 1;
  }
  return 1;
};
